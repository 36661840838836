import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import { configurationPageTabName, routes } from "../../constants/navigation";
const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(1)
  }
}));
export default function ConfigurationPage({
  match,
  history
}) {
  const classes = useStyles();
  const [tab, setTab] = useState<configurationPageTabName>();
  useEffect(() => {
    if (match.params.tab) {
      setTab(match.params.tab);
    } else {
      history.push(routes.configurationPricebookListing);
    }
  }, [history, match.params.tab]);
  if (!tab) {
    return null;
  }
  return <>
      <Card className={classes.card} data-sentry-element="Card" data-sentry-source-file="ConfigurationPage.tsx">
        <CardHeader title="Configuration" data-sentry-element="CardHeader" data-sentry-source-file="ConfigurationPage.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ConfigurationPage.tsx" />
        <AppBar enableColorOnDark position="static" color="inherit" data-sentry-element="AppBar" data-sentry-source-file="ConfigurationPage.tsx">
          <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={(_event, newTab) => {
          if (tab !== newTab) {
            setTab(newTab);
          }
        }} scrollButtons={false} variant="scrollable" data-sentry-element="Tabs" data-sentry-source-file="ConfigurationPage.tsx">
            <Tab key={configurationPageTabName.pricebook} value={configurationPageTabName.pricebook} label="Pricebook" component={Link} to={routes.configurationPricebookListing} data-sentry-element="Tab" data-sentry-source-file="ConfigurationPage.tsx" />
            <Tab key={configurationPageTabName.partnerBillingRules} value={configurationPageTabName.partnerBillingRules} label="Partner Billing Rules" component={Link} to={routes.configurationPartnerBillingRules} data-sentry-element="Tab" data-sentry-source-file="ConfigurationPage.tsx" />
          </Tabs>
        </AppBar>
      </Card>
    </>;
}